/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { useJwtNoti as service } from './service'

export default {
  getNotifications(params) {
    return service.get('/notifications/history/lazy-load', params)
  },

  getNotiById(id) {
    return service.get(`/notifications/${id}`)
  },

  resendStop(id = '') {
    return service.patch(`/notifications/${id}/resend/stop`)
  },

  resendRestart(id = '') {
    return service.patch(`/notifications/${id}/resend/restart`)
  },

  readNoti(id = '') {
    return service.patch(`/notifications/${id}/read`)
  },

  readAllNoti(payload) {
    return service.post('/notifications/read-all', payload)
  },

  unReadCountNoti(params) {
    return service.get('/notifications/history/unread-count', params)
  },
}
