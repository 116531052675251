import service from './service'

export default {
  addAuditLog(payload = {}) {
    return service.post('/audit_logs', payload)
  },

  fetchAuditLogs(params = {}) {
    return service.get('/audit_logs', params)
  },
}
