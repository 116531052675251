/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import { useJwtUser as service } from './service'

export default {
  authzClient(_payload = {}) {
    return service.get('/authz/clients', _payload)
  },

  getCustomRole() {
    return service.get('/authz/custom-roles')
  },

  getResouceByClientId(id = '') {
    return service.get(`/authz/${id}/resources`)
  },

  createdRole(payload = {}) {
    return service.post('/authz/custom-roles', payload)
  },

  setPermissionInRole(payload = {}, clientId, roleName) {
    return service.post(
      `/authz/${clientId}/role/${roleName}/permissions/bulk`,
      { permissions: payload },
    )
  },

  getPermissionInRole(clientId, roleName) {
    return service.get(`/authz/${clientId}/role/${roleName}/permissions`)
  },

  getResourceById(clientId, reSourceId) {
    return service.get(`/authz/${clientId}/resources/${reSourceId}`)
  },

  deleteRole(roleName = '') {
    return service.delete(`/authz/custom-roles/${roleName}`)
  },

  getUserCustomRoles(id) {
    return service.get(`/users/${id}/custom-roles`)
  },

  setUserCustomRoles(id, payload) {
    return service.post(`/users/${id}/custom-roles`, payload)
  },

  deleteUserCustomRoles(id, payload) {
    return service.delete(`/users/${id}/custom-roles`, payload)
  },

  getGrantedScopes(userId) {
    // user's permission
    return service.get(`/authz/granted-scopes/user/${userId}`)
  },
  getFunctionScopes() {
    return service.get('/function_scopes')
  },
}
