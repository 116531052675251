import service from './service'

export default {
  getAgencyBalance() { // số dư hãng
    return service.get('/flights/agency-balance')
  },
  updateAgencyBalance(payload) {
    return service.patch('/agency_balance_configs/update', payload)
  },
}
