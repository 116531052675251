/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchScheduleCalendars(_payload = {}) {
    return service.get('/schedule_calendars', _payload)
  },

  createScheduleCalendar(body) {
    return service.post('/schedule_calendars', body)
  },

  updateScheduleCalendar(id = '', payload = {}) {
    return service.patch(`/schedule_calendars/${id}`, payload)
  },

  deleteScheduleCalendar(id) {
    return service.delete(`/schedule_calendars/${id}`)
  },
}
