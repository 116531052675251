import axios from 'axios'
// import Vue from 'vue'

import useJwt from '@core/auth/jwt/useJwt'

export const instanceCms = axios.create({
  baseURL: process.env.VUE_APP_API_CMS,
})

const { jwt } = useJwt(instanceCms, {})
export default jwt
