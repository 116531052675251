/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchAllReceipts(_payload = {}) {
    return service.get('/receipts', _payload)
  },

  fetchReceiptsByFilter(_payload = {}) {
    return service.post('/receipts/search', _payload)
  },

  deleteReceipt(id = '') {
    return service.delete(`/receipts/${id}`)
  },

  addReceipt(payload = {}) {
    return service.post('/receipts', payload)
  },

  updateReceipt(id = '', payload = {}) {
    return service.patch(`/receipts/${id}`, payload)
  },
  exportReceipt(payload = {}, fileName) {
    return service.excel('/receipts/export', payload, fileName)
  },
}
