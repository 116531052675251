/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchScheduleTags(_payload = {}) {
    return service.get('/schedule_tags', _payload)
  },
  bulkInsertScheduleTag(body) {
    return service.post('/schedule_tags/bulk-insert', body)
  },
  deleteScheduleTag(id) {
    return service.delete(`/schedule_tags/${id}`)
  },
  updateScheduleTag(id, body = {}) {
    return service.patch(`/schedule_tags/${id}`, body)
  },
}
