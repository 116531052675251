var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "".concat(_vm.skinClasses, " theme-").concat(_vm.themeColor, " h-100"),
    attrs: {
      "id": "app"
    }
  }, [_c(_vm.layout, {
    tag: "component"
  }, [_c('router-view')], 1), _vm.enableScrollToTop ? _c('scroll-to-top') : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "modal-api-loading",
      "visible": _vm.apiLoading,
      "aria-label": "Loading...",
      "hide-header": "",
      "hide-footer": "",
      "hide-backdrop": "",
      "centered": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "body-class": "d-flex-center flex-column"
    }
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _vm.textLoading ? _c('div', [_vm._v(" " + _vm._s(_vm.textLoading) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }