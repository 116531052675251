// import useJwt from '@/api/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  return (
    (localStorage.getItem('userData') && localStorage.getItem('accessToken'))
    || (sessionStorage.getItem('userData')
      && sessionStorage.getItem('accessToken'))
  )
}

export const getUserData = () => {
  let storage
  let userData
  let employeeData
  if (
    localStorage.getItem('userData')
    && localStorage.getItem('employeeData')
  ) {
    storage = localStorage
    userData = JSON.parse(localStorage.getItem('userData'))
    employeeData = JSON.parse(localStorage.getItem('employeeData'))
  } else if (
    sessionStorage.getItem('userData')
    && sessionStorage.getItem('employeeData')
  ) {
    storage = sessionStorage
    userData = JSON.parse(sessionStorage.getItem('userData'))
    employeeData = JSON.parse(sessionStorage.getItem('employeeData'))
  }
  // const userData = JSON.parse(localStorage.getItem('userData')) || JSON.parse(sessionStorage.getItem('userData'))
  // const employeeData = JSON.parse(localStorage.getItem('employeeData')) || JSON.parse(sessionStorage.getItem('employeeData'))
  return { userData, employeeData, storage }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} user user Data
 */
export const getHomeRouteForLoggedInUser = (user, callbackUrl) => {
  if (callbackUrl) {
    return { path: callbackUrl }
  }
  const userRole = user?.type
  const parentAgencyId = user?.agency?.parentAgency?.id
  const userFloor = !parentAgencyId ? 'F1' : parentAgencyId === process.env.VUE_APP_MAMA_AGENCY_ID ? 'F2' : 'F3'
  if (!userRole || !user) return { name: 'auth-login' }
  if (['ADM', 'OPE', 'BE', 'SE', 'CTV'].includes(userRole)) return { name: 'apps-flightsV2-page' }
  if (['KTT', 'KTV'].includes(userRole)) {
    if (userFloor === 'F1' && userRole === 'KTT') return { name: 'apps-flights-agencyBalance' }
    return { name: 'apps-saleReport' }
  }
  return { name: 'auth-login' }
}
