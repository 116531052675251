<template>
  <span :class="`position-relative d-flex-center ${customClass}`">
    <component
      :is="SvgIcon"
      :svg-string="icons[icon]"
      :size="size"
      :color="color"
    />
  </span>
</template>

<script>
import convertSVGToVueComponent from '@/components/icons/convertSvg'

import icons from '@icons'

export default {
  name: 'IAmIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: '',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { icons }
  },
  computed: {
    SvgIcon() {
      return convertSVGToVueComponent()
    },
  },
}
</script>

<style lang="scss">
.feather-icon svg {
  outline: none;
}
</style>
