import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

import IconSvg from '@/views/apps/icons/IconSvg.vue'
import aclPlugin from '@/plugins/acl'
import appBreakPoint from '@/plugins/appbreakpoint'
import IAmIcon from '@/components/IAmIcon.vue'
import vInputFormatter from '@/@core/directives/input/vInputFormatter'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
// import { numbericOnly } from '@core/directives/input/numbericOnly'
import removeNonNumericChars from '@core/directives/input/removeNonNumericChars'
// import removeNonLetterChars from '@core/directives/input/removeNonLetterChars'

Vue.use(aclPlugin)
Vue.use(appBreakPoint)

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('IAmIcon', IAmIcon)
Vue.component('IconSvg', IconSvg)
// Vue.directive('numberic-only', numbericOnly)
Vue.directive('remove-non-numeric-chars', removeNonNumericChars)
// Vue.directive('remove-non-letter-chars', removeNonLetterChars)
Vue.directive('input-formatter', vInputFormatter)
Vue.directive('ripple', Ripple)
Vue.directive('b-tooltip', VBTooltip)
