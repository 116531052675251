var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BButton', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(" Click vào đây để cài đặt mật khẩu thanh toán. ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }