import service from './service'

const ERROR_NOTI = '/error_notification_invoices'

export default {
  fetchErrorNoti(param = {}) {
    return service.post(`${ERROR_NOTI}/search`, param)
  },

  getErrorNotiById(id) {
    return service.get(`${ERROR_NOTI}/${id}`)
  },

  deleteErrorNoti(id) {
    return service.delete(`${ERROR_NOTI}/${id}`)
  },

  refuseToSign(param = {}, id) {
    return service.post(`${ERROR_NOTI}/${id}/refuse_to_sign`, param)
  },

  createErrorNoti(param = {}) {
    return service.post(`${ERROR_NOTI}/`, param)
  },

  sendFptTvan(param = {}, id = '') {
    return service.post(`${ERROR_NOTI}/${id}/send-fpt-tvan`, param)
  },

  exportErrorNoti(payload = {}, fileName) {
    return service.excel(`${ERROR_NOTI}/export`, payload, fileName)
  },
}
