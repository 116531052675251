import service from './service'

export default {
  uploadImage(data) {
    return service.upload('/media/upload', data)
  },
  uploadUserFile(data) { // upload mỗi user 1 folder
    return service.upload('/media/users/upload', data)
  },
  getGlobalConfig() {
    return service.get('/fe_configs')
  },

  // UTILS
  currencyConversion(payload) {
    return service.post('/utils/currency-conversion', payload)
  },
  referenceDataSearch(payload) {
    return service.post('/utils/reference-data-search', payload)
  },
}
