<template lang="">
  <div>
    <div class="text-left mb-0">
      {{ $t('flight[\'Duplicate bookings\']') }}:
      <div
        v-for="(item, index) in codeList"
        :key="item?.id"
      >
        {{ index + 1 }}.
        <BButton
          variant="link"
          class="font-weight-bolder p-25 hover-underline"
          @click="handleClick(item)"
        >
          {{ item.bookingCode }}
        </BButton>
        <div v-if="item.passengerName">
          {{ $t('flight.passenger') }}: {{ item.passengerName }}
        </div>
        <div v-if="item.trips">
          {{ $t('flight.flight') }}: {{ item.trips }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'

import { useRouter } from '@/@core/utils/utils'

export default {
  components: { BButton },
  props: {
    codeList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { router } = useRouter()
    function handleClick(item) {
      if (item?.id) {
        router.push({ name: 'apps-reservations-modify', params: { id: item.id } })
      } else if (item?.bookingCode) {
        const source = 'VJ'
        router.push({ name: 'apps-reservations-modify', params: { id: `${source}-${item.bookingCode}` } })
      }
    }
    return { handleClick }
  },
}
</script>

<style lang="scss" scoped>
li:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: #cccccc50;
  border-radius: 4px;
}
</style>
