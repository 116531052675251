import service from './service'

export default {
  getAllReport(payload = {}) {
    return service.get('/retails/report', payload)
  },

  getAllBookingRetail(payload = {}) {
    return service.get('/retails/booking', payload)
  },
}
