var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BButton', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.mfa.linkToMfa')) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }