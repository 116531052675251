/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

const domain = '/sepay'
export default {
  getBankAccounts(params) {
    return service.get(`${domain}/bank-accounts`, params)
  },
  getCompanies(params) {
    return service.get(`${domain}/companies`, params)
  },
  getTransactions(params) {
    return service.get(`${domain}/transactions`, params)
  },
  getBankAccount(id) {
    return service.get(`${domain}/bank-accounts/${id}`)
  },
  getCompany(id) {
    return service.get(`${domain}/companies/${id}`)
  },
  getTransaction(id) {
    return service.get(`${domain}/transactions/${id}`)
  },
  counterCompany(id, params) {
    return service.get(`${domain}/companies/${id}/counter`, params)
  },
  getConfigurationCompany(id) {
    return service.get(`${domain}/companies/${id}/config`)
  },
  configurationCompany(id, body) {
    return service.post(`${domain}/companies/${id}/config`, body)
  },
  updateCompany(id, body) {
    return service.post(`${domain}/companies/${id}/update`, body)
  },
}
