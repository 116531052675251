import axios from 'axios'

import service from './service'

const VUE_APP_API_TOOL_SIGN_INVOICE = process.env.VUE_APP_API_TOOL_SIGN_INVOICE

export default {
  searchInvoice(payload = {}) {
    return service.post('/invoices/search', payload)
  },

  createInvoice(payload = {}) {
    return service.post('/invoices', payload)
  },

  getCompanyByTaxCode(code) {
    return service.get(`/company/tax-code/${code}`)
  },

  getInvoiceById(id) {
    return service.post(`/invoices/${id}/retrieve`)
  },

  getInvoicePdfById(id) {
    return service.pdf(`/invoices/${id}/pdf`)
  },

  getTransmissionHistory(id) {
    return service.get(`/invoices/${id}/get-transmission-history`)
  },

  updateInvoice(id, payload = {}) {
    return service.put(`/invoices/${id}`, payload)
  },

  getInvoiceTemplate(companyId) {
    return service.get(`/company/${companyId}/invoice-template`)
  },

  getTags(text) {
    return service.get('/invoices/tags', { text })
  },

  // ký
  signInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/sign`, payload)
  },
  signAndReleaseInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/sign-and-release`, payload)
  },

  getCompanyCertificates(companyId) {
    return service.get(`/company/${companyId}/certificates`)
  },

  generateTokenForRedirectPortal(companyId) {
    return service.post(`/company/${companyId}/generate-token`)
  },

  // gửi cơ quan thuế
  releaseInvoice(invoiceId) {
    return service.post(`/invoices/${invoiceId}/release`)
  },

  // gửi email
  sendMailInvoice(invoiceId) {
    return service.post(`/invoices/${invoiceId}/send-mail`)
  },

  // replace
  replaceInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/replace-invoice`, payload)
  },

  // adjust
  adjustInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/adjust-invoice`, payload)
  },

  // duplicate
  duplicateInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/duplicate-invoice`, payload)
  },

  // cancel
  cancelInvoice(invoiceId, payload = {}) {
    return service.post(`/invoices/${invoiceId}/cancel-invoice`, payload)
  },
  cancelBatchInvoice(payload = {}) {
    return service.post('/invoices/cancel-batch', payload)
  },

  exportInvoices(payload = {}, fileName) {
    return service.excel('/invoices/export', payload, fileName)
  },

  getXmlInvoice(invoiceId) {
    return service.get(`/invoices/${invoiceId}/xml`)
  },

  signInvoiceByToken(invoiceId, payload = {}) {
    return service.put(`/invoices/${invoiceId}/signed-data`, payload)
  },

  releaseBatch(payload) {
    return service.post('/invoices/release-batch', payload)
  },

  getCountProcessingReleaseBatch() {
    return service.get('/invoices/release-batch/count-processing')
  },

  getCertsByToken() {
    return axios.get(`${VUE_APP_API_TOOL_SIGN_INVOICE}/certs`)
  },

  signInvoiceLocal(payload) {
    return axios.post(`${VUE_APP_API_TOOL_SIGN_INVOICE}/signing`, payload)
  },

  // INVOICE TICKET: VE NGOAI HE THONG
  createInvoiceTicket(payload = {}) {
    return service.post('/invoice-tickets', payload)
  },

  getInvoiceTickets(payload = {}) {
    return service.get('/invoice-tickets', payload)
  },

  bulkImportInvoiceTickets(payload = {}) {
    return service.post('/invoice-tickets/bulk-insert', payload)
  },

  getInvoiceTicketById(invoiceTicketId) {
    return service.get(`/invoice-tickets/${invoiceTicketId}`)
  },

  updateInvoiceTicket(invoiceTicketId, payload) {
    return service.patch(`/invoice-tickets/${invoiceTicketId}`, payload)
  },

  deleteInvoiceTicket(invoiceTicketId) {
    return service.delete(`/invoice-tickets/${invoiceTicketId}`)
  },

  exportTemplateInvoiceTickets(payload, fileName = 'MauNhapVeNgoai') {
    return service.excel('/invoice-tickets/export-template', payload, fileName)
  },

  // OLD
  // getInvoiceConfigByYear(year = new Date().getFullYear()) {
  //   return service.get(`/invoice_config/year/${year}`)
  // },

  // fetchInvoices(payload = {}) {
  //   return service.post('/invoices-old/search', payload)
  // },

  // getInvoiceById(id) {
  //   return service.get(`/invoices-old/${id}`)
  // },

  // addInvoice(payload = {}) {
  //   return service.post('/invoices', payload)
  // },

  // updateInvoice(id, payload = {}) {
  //   return service.put(`/invoices-old/${id}`, payload)
  // },

  // adjustInvoice(id, payload = {}) {
  //   return service.post(`/invoices-old/${id}/adjust`, payload)
  // },

  // replaceInvoice(id, payload = {}) {
  //   return service.post(`/invoices-old/${id}/replace`, payload)
  // },

  // requestApproveInvoices(payload = {}) {
  //   return service.post('/invoices-old/request-approval', payload)
  // },

  // refuseToApproveInvoices(payload = {}) {
  //   return service.post('/invoices-old/refuse-to-approve', payload)
  // },

  // approveInvoices(payload = {}) {
  //   return service.post('/invoices-old/approve', payload)
  // },

  // refuseToSignInvoices(payload = {}) {
  //   return service.post('/invoices-old/refuse-to-sign', payload)
  // },

  // cancelInvoice(id, payload = {}) {
  //   return service.post(`/invoices-old/${id}/cancel`, payload)
  // },

  // signInvoice(payload = {}) {
  //   return service.post('/invoices-old/signature/fpt-tvan', payload)
  // },

  // exportInvoice(payload = {}, fileName) {
  //   return service.excel('/invoices-old/export', payload, fileName)
  // },

  // sendMail(payload = {}) {
  //   return service.post('/invoices-old/send-mail', payload)
  // },
}
