import { useJwtProm as service } from './service'

export default {
  createPromotionAirline(body) {
    return service.post('/promotions_airline', body)
  },
  fetchPromotionAirline(params) {
    return service.get('/promotions_airline', params)
  },
  fetchPromotionAirlineAvailable(params) {
    return service.get('/promotions_airline/available', params)
  },
  getPromotionAirlineById(id) {
    return service.get(`/promotions_airline/${id}`)
  },
  updatePromotionAirline({ id, ...body }) {
    return service.patch(`/promotions_airline/${id}`, body)
  },
  deletePromotionAirline({ id }) {
    return service.delete(`/promotions_airline/${id}`)
  },
}
