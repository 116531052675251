/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

const domain = '/banks'
export default {
  getBanks(params) {
    return service.get(`${domain}`, params)
  },
  getBankById(id) {
    return service.get(`${domain}/${id}`)
  },
  update(id, params) {
    return service.patch(`${domain}/${id}`, params)
  },
  getLogsById(id) {
    return service.get(`${domain}/${id}/logs`)
  },
}
