export default function convertSVGToVueComponent() {
  return {
    name: 'SvgIcon',
    functional: true,
    props: {
      svgString: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: '14',
      },
      color: {
        type: String,
        default: 'black',
      },
    },
    render(createElement, context) {
      const { svgString, size, color } = context.props
      const parser = new DOMParser()
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml')
      const svgElement = svgDoc.documentElement

      svgElement.setAttribute('width', size)
      svgElement.setAttribute('height', size)

      function convertElementToVNode(element) {
        const tagName = element.tagName
        const attrs = {}
        Array.from(element.attributes).forEach(attr => {
          if (['stroke', 'fill'].includes(attr.name) && attr.nodeValue !== 'none') {
            attrs[attr.name] = color || 'currentColor'
          } else {
            attrs[attr.name] = attr.value
          }
        })

        const children = Array.from(element.childNodes).map(child => {
          if (child.nodeType === 1) {
            // Element node
            return convertElementToVNode(child)
          } if (child.nodeType === 3) {
            // Text node
            return child.textContent
          }
          return null
        }).filter(child => child !== null)

        return createElement(tagName, { attrs }, children)
      }

      return convertElementToVNode(svgElement)
    },
  }
}
