import service from './service'

export default {
  createSession(payload = {}) {
    return service.post('/terminals/create-session', payload)
  },
  executeTerminal(payload = {}) {
    return service.post('/terminals/execute', payload)
  },
  endSession(payload = {}) {
    return service.post('/terminals/end-session', payload)
  },
  pingSession(payload = {}) {
    return service.post('/terminals/ping-session', payload)
  },
}
