import service from './service'

export default {
  getCategory() {
    return service.get('/trains/systems/category')
  },
  getSetting() {
    return service.get('/trains/systems/setting')
  },
  searchTrain(payload) {
    return service.post('/trains/tickets/search', payload)
  },
  searchPromotions(payload) {
    return service.post('/trains/promotions/search', payload)
  },
  appliesPromotions(payload) {
    return service.post('/trains/promotions/applies', payload)
  },
  checkAdultTicket(payload) {
    return service.post('/trains/tickets/check-adult', payload)
  },
  fetchSeatByCoach(payload) {
    return service.post('/trains/tickets/seat-by-coach', payload)
  },
  autoPickTicket(payload) {
    return service.post('/trains/tickets/pick-auto', payload)
  },
  saveBookings(payload) {
    return service.post('/trains/tickets/save', payload)
  },

  // BOOKING LIST
  fetchBookingList(payload) {
    return service.post('/trains/bookings', payload)
  },

  // RETRIEVE
  retrieveBooking(payload) {
    return service.post('/trains/tickets/retrieve-booking', payload)
  },

  // BOOKING DETAIL
  getBookingById(bookingId) {
    return service.get(`/trains/bookings/${bookingId}`)
  },
  paymentTrains(payload) {
    return service.post('/trains/tickets/payment', payload)
  },
}
