import agencies from './agencies'
import agencyBalanceConfigs from './agencyBalanceConfigs'
import apiAuditLog from './auditLog'
import auth from './auth/index'
import bankAccount from './bankAccount'
import booking from './booking'
import customer from './customer'
import document from './document'
import employee from './employee'
import errorNoti from './errorNoti'
import general from './general'
import apiInvoice from './invoice'
import noti from './noti'
import packageConfig from './packageConfig'
import promotion from './promotion'
import promotionAirline from './promotionAirline'
import promotionUser from './promotionUser'
import receipt from './receipt'
import resellTicket from './resellTicket'
import reservation from './reservation'
import retail from './retail'
import role from './role'
import saleReports from './saleReports'
import schedule from './schedule'
import scheduleCalendar from './scheduleCalendar'
import scheduleTag from './scheduleTag'
import sepay from './sepay'
import terminals from './terminals'
import topup from './topup'
import apiPublic from './public'
import supportRequest from './supportRequest'
import bank from './bank'
import train from './train'
import apiUtil from './util'

// NOTE: consider delete default export
// should use named import, should not use default import
export default {
  auth: { ...auth },
  employee: { ...employee },
  customer: { ...customer },
  agencies: { ...agencies },
  general: { ...general },
  booking: { ...booking },
  receipt: { ...receipt },
  document: { ...document },
  reservation: { ...reservation },
  noti: { ...noti },
  role: { ...role },
  saleReports: { ...saleReports },
  errorNoti: { ...errorNoti },
  promotion: { ...promotion },
  promotionAirline: { ...promotionAirline },
  promotionUser: { ...promotionUser },
  schedule: { ...schedule },
  scheduleCalendar: { ...scheduleCalendar },
  scheduleTag: { ...scheduleTag },
  agencyBalanceConfigs: { ...agencyBalanceConfigs },
  retail: { ...retail },
  terminals: { ...terminals },
  packageConfig: { ...packageConfig },
  bankAccount: { ...bankAccount },
  train: { ...train },
}

// for named import, example:
// import { apiTopup } from '@/api/'
export {
  agencies as apiAgencies,
  agencyBalanceConfigs as apiAgencyBalanceConfigs,
  apiAuditLog,
  auth as apiAuth,
  bankAccount as apiBankAccount,
  booking as apiBooking,
  customer as apiCustomer,
  document as apiDocument,
  employee as apiEmployee,
  errorNoti as apiErrorNoti,
  general as apiGeneral,
  apiInvoice,
  noti as apiNoti,
  packageConfig as apiPackageConfig,
  promotion as apiPromotion,
  promotionAirline as apiPromotionAirline,
  promotionUser as apiPromotionUser,
  receipt as apiReceipt,
  resellTicket as apiResellTicket,
  reservation as apiReservation,
  retail as apiRetail,
  role as apiRole,
  saleReports as apiSaleReports,
  schedule as apiSchedule,
  scheduleCalendar as apiScheduleCalendar,
  scheduleTag as apiScheduleTag,
  sepay as apiSepay,
  terminals as apiTerminals,
  topup as apiTopup,
  supportRequest as apiSupportRequest,
  apiPublic,
  bank as apiBank,
  train as apiTrain,
  apiUtil,
}
