/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  fetchSchedules(_payload = {}) {
    return service.get('/schedules', _payload)
  },
  fetchEmployeesHasSchedules(_payload = {}) {
    return service.get('/employees/schedule', _payload)
  },
  bulkInsertSchedule(body) {
    return service.post('/schedules/bulk-insert', body)
  },
  bulkUpdateSchedule(body) {
    return service.patch('/schedules/bulk-update', body)
  },
  deleteSchedule(id) {
    return service.delete(`/schedules/${id}`)
  },
  deleteSchedulesByEmployeeId(employeeId, scheduleType) {
    return service.delete(`/schedules/${employeeId}/bulk-delete/${scheduleType}`)
  },
  updateSchedule(id, body = {}) {
    return service.patch(`/schedules/${id}`, body)
  },
}
