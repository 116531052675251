var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-left mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight[\'Duplicate bookings\']')) + ": "), _vm._l(_vm.codeList, function (item, index) {
    return _c('div', {
      key: item === null || item === void 0 ? void 0 : item.id
    }, [_vm._v(" " + _vm._s(index + 1) + ". "), _c('BButton', {
      staticClass: "font-weight-bolder p-25 hover-underline",
      attrs: {
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.bookingCode) + " ")]), item.passengerName ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.passenger')) + ": " + _vm._s(item.passengerName) + " ")]) : _vm._e(), item.trips ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.flight')) + ": " + _vm._s(item.trips) + " ")]) : _vm._e()], 1);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }