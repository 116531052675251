import { getToday } from '@core/utils/filter'

import service from './service'

const BASE_URL = '/sale_reports'
const BASE_URL_RETAIL = '/retails'

export default {
  fetchSaleReports(param = {}) {
    return service.get(`${BASE_URL}`, param)
  },

  getSaleReportById(id) {
    return service.get(`${BASE_URL}/details/${id}`)
  },

  fetchSaleReportsByAgency(param = {}) {
    return service.get(`${BASE_URL}/by-agency`, param)
  },
  bulkInsert(payload = {}) {
    return service.post(`${BASE_URL}/bulk-insert`, payload)
  },
  bulkUpdate(payload = {}) {
    return service.patch(`${BASE_URL}/updates`, payload)
  },
  bulkDelete(payload = {}) {
    return service.delete(`${BASE_URL}/bulk-delete`, payload)
  },

  exportSaleReport(payload = {}, fileName = `SaleReports_${getToday()}`) {
    return service.excel(`${BASE_URL}/export`, payload, fileName)
  },

  exportAccountPayable(payload = {}, fileName = `AcountsPayableList_${getToday()}`) {
    return service.excel(
      `${BASE_URL}/account-payable/export`,
      payload,
      fileName,
    )
  },

  exportAccountPayableDetail(payload = {}, fileName = `AcountsPayableDetails_${getToday()}`) {
    return service.excel(
      `${BASE_URL}/account-payable/details/export`,
      payload,
      fileName,
    )
  },

  exportSaleReportTemplate(payload = {}, fileName = `SaleReportsTemplate_${getToday()}`) {
    return service.excel(`${BASE_URL}/export-template`, payload, fileName)
  },

  fetchAccountPayable(param = {}) {
    return service.get(`${BASE_URL}/account-payable`, param)
  },

  accountPayableDetail(param = {}) {
    return service.get(`${BASE_URL}/account-payable/details`, param)
  },

  updateSaleReport(payload = {}) {
    return service.patch(`${BASE_URL}/updates`, payload)
  },

  getDate() {
    return service.get('/acc_payable/book-closing/get-date')
  },

  bookClosing(param = {}) {
    return service.post('/acc_payable/book-closing', param)
  },

  // ANCHOR Refund Ticket
  fetchRefundTicket(param = {}) {
    return service.get('/sale_reports/refund-ticket', param)
  },

  // ANCHOR Accounts Payable F1 view F2
  fetchListAccountsPayableF2(agencyId, param = {}) {
    return service.get(`${BASE_URL}/account-payable/f2/${agencyId}`, param)
  },

  fetchDetailAccountsPayableF2(agencyId, param = {}) {
    return service.get(`${BASE_URL}/account-payable/details/f2/${agencyId}`, param)
  },

  exportListAccountsPayableF2(agencyId, payload = {}, fileName = `AcountsPayableList_${getToday()}`) {
    return service.excel(`${BASE_URL}/account-payable/f2/${agencyId}/export`, payload, fileName)
    // AcountsPayableList_26-07-2023
  },

  exportDetailAccountsPayableF2(agencyId, payload = {}, fileName = `AcountsPayableDetails_${getToday()}`) {
    return service.excel(`${BASE_URL}/account-payable/details/f2/${agencyId}/export`, payload, fileName)
  }, // AcountsPayableDetail_26-07-2023

  // ANCHOR Sale Report F2
  fetchSaleReportsF2(agencyId, params = {}) {
    return service.get(`${BASE_URL}/f2/${agencyId}`, params)
  },

  exportSaleReportsF2(agencyId, payload, fileName = `SaleReports_${getToday()}`) {
    return service.excel(`${BASE_URL}/export/f2/${agencyId}`, payload, fileName)
  },

  // ANCHOR RETAIL
  fetchRetailSaleReports(param = {}) {
    return service.get(`${BASE_URL_RETAIL}/sale-reports`, param)
  },

  fetchRetailAccountPayableList(param = {}) {
    return service.get(`${BASE_URL_RETAIL}/account-payable`, param)
  },

  getRetailAccountPayableDetail(param = {}) {
    return service.get(`${BASE_URL_RETAIL}/account-payable/details`, param)
  },
}
