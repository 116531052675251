import service from './service'

export default {
  fetchBanks() {
    return service.get('/topup/banks')
  },

  fetchTopupQrCode(payload = {}) {
    return service.post('/topup/request', payload)
  },

  confirmTopup(payload = {}) {
    return service.post('/topup/confirm', payload)
  },

  confirmTopupManual(payload = {}) {
    return service.post('/topup/confirm/manual', payload)
  },

  fetchTopups(params = {}) {
    return service.get('/topup', params)
  },

  getTopupById(id) {
    return service.get(`/topup/${id}`)
  },

  getTopupByCode(code) {
    return service.get(`/topup/${code}/detail`)
  },

  deleteTopupById(id) {
    return service.delete(`/topup/${id}`)
  },

  exportTopups(payload = {}, fileName = 'Topup-list') {
    return service.excel('/topup/export', payload, fileName)
  },
}
