import service, { useJwtUser as serviceUser } from '../service'

export default {
  // ANCHOR forgot password
  forgotPassword(payload) {
    return service.post('/public/forgot-password', payload)
  },

  forgotPasswordByUsername(payload) {
    return service.post('/public/forgot-pw', payload)
  },

  validateOtp(payload) {
    return serviceUser.post('/auth/validate-otp', payload)
  },

  resetPassword(payload) {
    return serviceUser.post('/auth/reset-password', payload)
  },

  sendNotificationAll(payload) {
    return service.post('/public/send-notification-all', payload)
  },

  getUserInfo(userId) {
    return serviceUser.get(`/users/${userId}`)
  },
}
