<template lang="">
  <BButton
    variant="link"
    @click="handleClick"
  >
    {{ $t('myAccount.mfa.linkToMfa') }}
  </BButton>
</template>
<script>
import { BButton } from 'bootstrap-vue'

import { useRouter } from '@/@core/utils/utils'

export default {
  components: { BButton },
  setup() {
    const { router } = useRouter()
    function handleClick() {
      router.push({ name: 'account-settings', hash: '#mfa' })
      this.$swal.close()
    }
    return { handleClick }
  },
}
</script>

<style lang="scss" scoped>
li:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: #cccccc50;
  border-radius: 4px;
}
</style>
