/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

const domain = '/support_requests'
export default {
  findAll(params) {
    return service.get(`${domain}`, params)
  },
  create(req) {
    return service.post(`${domain}`, req)
  },
  update(id, req) {
    return service.patch(`${domain}/${id}`, req)
  },
  getById(id) {
    return service.get(`${domain}/${id}`)
  },
  getByCode(code) {
    return service.get(`${domain}/get-by-pnr/${code}`)
  },
  getLogsById(id) {
    return service.get(`${domain}/${id}/logs`)
  },
  updateStatus(id) {
    return service.patch(`${domain}/${id}/active`)
  },
  complete(id) {
    return service.post(`${domain}/${id}/complete`)
  },
  getBookingByCode(code) {
    return service.get(`${domain}/booking/${code}`)
  },
}
