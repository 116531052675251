var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: "position-relative d-flex-center ".concat(_vm.customClass)
  }, [_c(_vm.SvgIcon, {
    tag: "component",
    attrs: {
      "svg-string": _vm.icons[_vm.icon],
      "size": _vm.size,
      "color": _vm.color
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }