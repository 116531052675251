/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

const domain = '/resell-tickets'
export default {
  findAll(params) {
    return service.get(`${domain}`, params)
  },
  findManagerAll(params) {
    return service.get(`${domain}/manager`, params)
  },
  create(req) {
    return service.post(`${domain}`, req)
  },
  update(id, req) {
    return service.put(`${domain}/${id}`, req)
  },
  delete(id) {
    return service.delete(`${domain}/${id}`)
  },
  updateStatus(id) {
    return service.patch(`${domain}/${id}/active`)
  },
  statistics() {
    return service.get(`${domain}/report`)
  },
  getReportsById(id) {
    return service.get(`${domain}/${id}/reports`)
  },
  getSellById(id) {
    return service.get(`${domain}/${id}`)
  },
  createReport(id, req) {
    return service.post(`${domain}/${id}/report`, req)
  },
  getPaxInfoByPnr(pnr) {
    return service.get(`${domain}/book/${pnr}`)
  },
  banAGByAGId(agId, day) {
    return service.post(`${domain}/${agId}/ban-ag`, { day })
  },
  unbanAGByAGId(agId, day) {
    return service.post(`${domain}/${agId}/unban-ag`, { day })
  },
  sold(id, body) {
    return service.post(`${domain}/${id}/sold`, body)
  },
  search(params) {
    return service.post(`${domain}/search`, params)
  },
}
