/* eslint-disable no-unused-vars */
import axios from 'axios'
import Vue from 'vue'

import useJwt from '@core/auth/jwt/useJwt'

import { instanceUser } from './useJwt'

export const instanceProm = axios.create({
  baseURL: process.env.VUE_APP_API_PROM,
})

const { jwt: jwtProm } = useJwt(instanceProm, {}, instanceUser)

export default jwtProm
